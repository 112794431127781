// useNativeRoute to handle layout route issue
// https://github.com/nuxt/nuxt/issues/21340#issuecomment-1858900454
import { useRoute as useNativeRoute } from 'vue-router';
import setupAmpSRSegmentWrapper from '@amplitude/segment-session-replay-wrapper';

interface UseWorkspaceParams {
  builder?: boolean;
  isPublic?: boolean;
  tenantSlug?: string;
}

export const useWorkspace = (params: UseWorkspaceParams = {}) => {
  // Composables
  useZazos();
  const $route = useNativeRoute();
  const $rollbar = useRollbar();
  const theme = useTheme();
  const { $segmentIdentifyUser, $store, $config, $auth } = useNuxtApp();

  // Methods
  const loadWorkspaceData = () => {
    if (!$auth.user && !params.isPublic) return;

    if (params.isPublic) {
      params.tenantSlug = $route.params?.tenantSlug as string;
    }

    try {
      $store.dispatch('workspace/loadWorkspace', params);
    } catch (error) {
      $rollbar.error('Workspace load', error);
    }
  };

  const identifyUser = () => {
    if (!$auth.user) return;

    $segmentIdentifyUser();
  };

  const setupAmplitudeSessionReplay = () => {
    if (!$auth.user) return;
    if ($auth.user.username?.endsWith('@zazos.com')) return;
    if ($config.public.deployEnv === 'staging') return;

    setupAmpSRSegmentWrapper({
      segmentInstance: window.analytics,
      amplitudeApiKey: $config.public.amplitudeApiKey,
      sessionReplayOptions: {
        sampleRate: 1,
      },
    });
  };

  watch(() => $store.getters['workspace/primaryColor'], (primaryColor) => {
    theme.themes.value.light.colors.primary = primaryColor;
    theme.themes.value.dark.colors.primary = primaryColor;
  }, { immediate: true });

  onBeforeMount(() => {
    loadWorkspaceData();
    identifyUser();
    setupAmplitudeSessionReplay();
  });

  onMounted(() => {
    $store.dispatch('features/fetchFeatures');

    Object.keys($route.query).filter(key => key.startsWith('feature_')).forEach((key) => {
      const flag = key.replace('feature_', '');
      const value = $route.query[key] === 'true';
      $store.dispatch('features/toggleFeature', { flag, value });
    });
  });
};
